import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {ThemeProvider} from "@mui/material/styles";
import {Link} from "react-router-dom";
import theme from "../theme";
import MyCopyright from "../modules/common/copyright";
import {ym_hit} from "../utils/yandex";
import Divider from "@mui/material/Divider";
import {Modal} from "@mui/material";


export default function HomePage() {
    ym_hit('/');

    return (
        <>
            <ThemeProvider theme={theme}>

                <CssBaseline/>

                <AppBar position="relative">
                    <Toolbar>
                        <img
                            src="/logo_nobg.png"
                            alt="logo"
                            width="40px"
                            height="40px"
                        />

                        <Typography sx={{ml: 2}}
                                    variant="h6" color="inherit" noWrap>
                            TwitchBot
                        </Typography>

                    </Toolbar>
                </AppBar>
                <main>
                    {/* Hero unit */}

                    <Box
                        sx={{
                            bgcolor: 'background.paper',
                            pt: 8,
                            pb: 6,
                        }}
                    >
                        <Container maxWidth="sm">
                            <Typography
                                component="h1"
                                variant="h2"
                                align="center"
                                color="text.primary"
                                gutterBottom
                            >
                                Simple bot for your Twitch channel
                            </Typography>
                            <Typography variant="h5" align="center" color="text.secondary" paragraph>
                                It can do a lot of things, like:
                                Voice messages, chat commands, timers, polls, and more!
                                More info could be found in our <a href="https://docs.jeetbot.cc/">documentation</a>.
                            </Typography>
                            <Stack
                                sx={{pt: 4}}
                                direction="row"
                                spacing={2}
                                justifyContent="center"
                            >
                                <Link to="/sign-in">
                                    <Button variant="contained">Manage my bot</Button>
                                </Link>
                                <Link to="/sign-in">
                                    <Button variant="outlined">Add to my channel</Button>
                                </Link>
                            </Stack>
                        </Container>
                    </Box>

                </main>
                <Box sx={{bgcolor: 'background.paper', p: 6}} component="footer">
                    <Typography
                        variant="subtitle1"
                        align="center"
                        color="text.secondary"
                        component="p"
                    >
                        Вы можете поддержать разработку бота на <a href="https://boosty.to/alexue4">Бусти</a>.
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        align="center"
                        color="text.secondary"
                        component="p"
                    >
                        Так же не забудьте подписаться на
                        наш <a href="https://t.me/+voXzDHQLmVs1OTky">телеграм канал</a> чтобы
                        не пропустить новых обновлений и участвовать в обсуждении функций бота.
                    </Typography>
                    <MyCopyright/>
                </Box>
                {/* End footer */}
            </ThemeProvider>
        </>
    );
}