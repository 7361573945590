import * as React from 'react';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {
    Autocomplete,
    Breadcrumbs,
    Checkbox,
    FormControlLabel,
    InputLabel,
    MenuItem,
    NativeSelect,
    Select,
    Tooltip
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { defer, Link as RouterLink, useLoaderData } from "react-router-dom";
import Container from "@mui/material/Container";
import Requester from "../../../utils/requester";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import {ym_hit} from "../../../utils/yandex";

const API_URL = "channel/tts_settings/"

export const ttsSettingsLoader = async ({ params }) => {
    const response = await Requester.get(API_URL)
    const response_data = await response.json()
    console.log(response_data)
    return defer(response_data)
}

function get_me_voice(voices, current_voice){
    let me_voice = voices.find(voice => voice[0] === current_voice)
    if (me_voice === undefined){
        return voices[0]
    }
    return me_voice

}
export default function TtsSettings() {

    const tts_settings = useLoaderData();
    const [ selectState, setSelectState ] = React.useState({
        current_channel_default_voice: tts_settings.current_channel_default_voice,
        tts_additional_voice_for_me_message: tts_settings.tts_additional_voice_for_me_message
    })
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let dataToSend = {
            tts_enabled: Boolean(data.get('tts_enabled')),
            filter_bad_words_tts: Boolean(data.get('filter_bad_words_tts')),
            only_ru_tts: Boolean(data.get('only_ru_tts')),
            tts_clean_names: Boolean(data.get('tts_clean_names')),
            translate_tts: Boolean(data.get('translate_tts')),
            current_channel_default_voice: selectState.current_channel_default_voice,
            tts_additional_voice_for_me_message: selectState.tts_additional_voice_for_me_message
        }
        Requester.post(API_URL, dataToSend)
            .then(response => {
                if (response.status === 200) {
                    window.location.href = '/dashboard/tts-settings';
                }
                if (response.status === 400) {
                    response.json().then(data => {
                        console.log(data)
                    })
                }
            })
    };
    ym_hit('/dashboard/tts-settings')
    return (
        <>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" to={"/dashboard"} component={RouterLink}>
                                Home
                            </Link>
                            <Typography color="text.primary">Управление озвучкой сообщений</Typography>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                <Grid container spacing={3} mt={2}>
                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            control={<Checkbox color="secondary" name="tts_enabled"
                                                value="yes"
                                                defaultChecked={tts_settings.tts_enabled}
                                            />}
                                            label="Включить озвучку сообщений в чате"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            control={<Checkbox color="secondary" name="filter_bad_words_tts"
                                                value="yes"
                                                defaultChecked={tts_settings.filter_bad_words_tts}
                                            />}
                                            label="Фильтровать мат и банворды"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Tooltip
                                            title="Автоматически конвертируем весь текст в русский транслитом и используем русские голоса"
                                            placement="top">
                                            <FormControlLabel
                                                control={<Checkbox color="secondary" name="only_ru_tts"
                                                    value="yes"
                                                    defaultChecked={tts_settings.only_ru_tts}
                                                />}
                                                label="Всегда озвучивать на русском"
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Tooltip
                                            title="Бот будет автоматически удалять упоминания или ники пользователей из чата"
                                            placement="top">
                                            <FormControlLabel
                                                control={<Checkbox color="secondary" name="tts_clean_names"
                                                    value="yes"
                                                    defaultChecked={tts_settings.tts_clean_names}
                                                />}
                                                label="Вырезать имена"
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Tooltip
                                            title="Если включено, бот сообщения на других языках будут автоматически переведены на русский, и озвучены русским голосом"
                                            placement="top">
                                            <FormControlLabel
                                                control={<Checkbox color="secondary" name="translate_tts"
                                                    value="yes"
                                                    defaultChecked={tts_settings.translate_tts}
                                                />}
                                                label="Переводить сообщения"
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                            Стандартный голос озвучки
                                        </InputLabel>
                                        <Tooltip
                                            title="Будет использован для всех зрителей, кто не сменил голос"
                                            placement="top">
                                            <Autocomplete
                                                disablePortal
                                                id="current_channel_default_voice_select"
                                                options={tts_settings.allowed_default_voices.map((voice) => (
                                                    {label: voice, id: voice}
                                                    ))}
                                                sx={{ width: 300 }}
                                                onChange={(event, newValue) => {
                                                    setSelectState({
                                                        ...selectState,
                                                        current_channel_default_voice: newValue.id
                                                    })
                                                }}

                                                defaultValue={{label: tts_settings.current_channel_default_voice, id: tts_settings.current_channel_default_voice}}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                            Голос для озвучки /me
                                        </InputLabel>
                                        <Tooltip
                                            title="Включает возможность озвучки сообщения /me выбранным голосом"
                                            placement="top">
                                            <Autocomplete
                                                disablePortal
                                                id="tts_additional_voice_for_me_message_select"
                                                options={tts_settings.tts_additional_voice_for_me_message_options.map((voice) => (
                                                    {label: voice[1], id: voice[0]}
                                                ))}
                                                sx={{ width: 300 }}
                                                onChange={(event, newValue) => {
                                                    setSelectState({
                                                        ...selectState,
                                                        tts_additional_voice_for_me_message: newValue.id
                                                    })
                                                }}

                                                defaultValue={
                                                    {
                                                        label: get_me_voice(tts_settings.tts_additional_voice_for_me_message_options, tts_settings.tts_additional_voice_for_me_message)[1],
                                                        id: get_me_voice(tts_settings.tts_additional_voice_for_me_message_options, tts_settings.tts_additional_voice_for_me_message)[0]
                                                    }
                                                }
                                                renderInput={(params) => <TextField {...params} />}
                                            />

                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                {/*//save button*/}
                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Save
                                    </Button>

                                </Grid>
                                {tts_settings.tts_enabled && <>
                                    {/*    TTs link field password like with auto copy*/}
                                    <Grid item xs={12} mt={4}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Ссылка для озвучки"
                                            variant="outlined"
                                            fullWidth
                                            value={tts_settings.tts_link}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>

                                    <Link to={"https://docs.jeetbot.cc/text-to-speech/basic-usage"}
                                        component={RouterLink}
                                        color="inherit" underline="hover">

                                        <Grid item xs={12} mt={4}>
                                            <Tooltip title="Документация">
                                                <MenuBookIcon sx={{ fontSize: 40 }} />
                                            </Tooltip>
                                            <Typography>Подробнее о установке в нашей документации</Typography>
                                        </Grid>
                                    </Link>

                                </>}
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
        ;
}