import {ThemeProvider} from "@mui/material/styles";
import theme from "../theme";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MyCopyright from "../modules/common/copyright";
import * as React from "react";
import {useNavigate} from "react-router-dom";

export default function NotImplementedPage() {
    const navigate = useNavigate();
    return (
        <>
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs">
                    <Box>
                        <Typography variant="h6" align="center" gutterBottom>
                            This page is not implemented yet.
                            <Button variant="contained" onClick={() => navigate(-1)}>
                                Go back
                            </Button>
                        </Typography>
                    </Box>
                    <MyCopyright sx={{mt: 8, mb: 4}}/>
                </Container>
            </ThemeProvider>
        </>
    );

}