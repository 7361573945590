import {lightBlue, red} from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    action: {
      active: lightBlue[200],
      activeOpacity: 1,
      hover: lightBlue[100],
      hoverOpacity: 0.7,
      focus: lightBlue[600],
      focusOpacity: 1,
      selected: lightBlue[300],
      selectedOpacity: 1
    },
  },
});

export default theme;
