import * as React from 'react';
import {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {Alert, Breadcrumbs, Checkbox, FormControlLabel, InputLabel, NativeSelect, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import {defer, Link as RouterLink, useLoaderData} from "react-router-dom";
import Container from "@mui/material/Container";
import Requester from "../../../utils/requester";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import {ym_hit} from "../../../utils/yandex";

const getChannelData = async () => {
    const response = await Requester.get(`channel/edit/`)
    const channel = await response.json()
    console.log(channel)
    return channel
}

export const channelFormLoader = async () => {
    const channel = await getChannelData()
    return defer(channel)
}

export default function ChannelForm() {

    let channel = useLoaderData();
    const [channelData, setChannelData] = useState(channel)
    const [state, setState] = useState({
        successSaved: false,
        loading: false
    })

    useEffect(() => {
        setChannelData(channel)
    }, [channel])

    const handleSubmit = (event) => {
        event.preventDefault();
        if (state.loading) {
            return
        }
        setState({ ...state, loading: true })

        const data = new FormData(event.currentTarget);
        let dataToSend = {
            is_active: Boolean(data.get('is_active')),
            enable_rate_finder: Boolean(data.get('enable_rate_finder')),
            channel_currency: data.get('channel_currency'),
            translation_enabled: Boolean(data.get('translation_enabled')),
            keyboard_translation_enabled: Boolean(data.get('keyboard_translation_enabled')),
            top_enabled: Boolean(data.get('top_enabled')),
        }
        try {
            Requester.post(`channel/edit/`, dataToSend)
                .then(async response => {
                    if (response.status === 200) {
                        getChannelData().then(data => {
                            setChannelData(data)
                            setState({ ...state, successSaved: true })
                        })
                    }
                    if (response.status === 400) {
                        response.json().then(data => {
                            console.log(data)
                        })
                    }
                })

        } catch (e) {
            console.log(e)
        } finally {
            setState({ ...state, loading: false })
        }
    };
    ym_hit('/dashboard/channel-edit');
    return (
        <>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" to={"/dashboard"} component={RouterLink}>
                                Home
                            </Link>
                            <Typography color="text.primary">Edit channel</Typography>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                <Typography variant="h5" gutterBottom>
                                    Основные настройки
                                </Typography>
                                <Divider orientation="horisontal" flexItem />
                                <Grid container spacing={3} mt={2}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            defaultValue={channelData.name}
                                            id="name"
                                            disabled={true}
                                            autoComplete={false}
                                            label="Название канала"
                                            fullWidth
                                            variant="standard"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {channelData.is_bot_banned &&
                                            <Alert severity="error">Бот был забанен на канале, если вы уже разбанили его, достаточно просто активировать бота</Alert>
                                        }
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            control={<Checkbox color="secondary" name="is_active" value="yes"
                                                defaultChecked={channelData.is_active}
                                            />}
                                            label="Активен ли бот"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            defaultValue={channelData.twitch_id}
                                            disabled={true}
                                            id="twitch_id"
                                            label="Twitch ID"
                                            fullWidth
                                            variant="standard"
                                        />
                                    </Grid>

                                </Grid>
                                <Typography variant="h5" gutterBottom mt={2}>
                                    Конвертер валют
                                </Typography>
                                <Divider orientation="horisontal" flexItem />
                                <Grid container spacing={3} mt={2}>
                                    <Grid item xs={6}>
                                        <Tooltip
                                            title="Бот будет автоматически находить в чате сообщения с валютой и конвертировать их в дефолтную валюту канала"
                                            placement="top">
                                            <FormControlLabel
                                                control={<Checkbox color="secondary" name="enable_rate_finder"
                                                    value="yes"
                                                    defaultChecked={channelData.enable_rate_finder}
                                                />}
                                                label="Активироват автоматическую конвертацию"
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                            Дефолтная валюта
                                        </InputLabel>
                                        <NativeSelect
                                            defaultValue={channelData.channel_currency}
                                            name="channel_currency"
                                            inputProps={{
                                                name: 'channel_currency',
                                                id: 'uncontrolled-native',
                                            }}
                                        >
                                            {channelData.currencies.map((currency) => (
                                                <option value={currency} key={currency}>{currency}</option>
                                            ))}
                                        </NativeSelect>
                                    </Grid>

                                </Grid>
                                <Typography variant="h5" gutterBottom mt={2}>
                                    Переводчик
                                </Typography>
                                <Divider orientation="horisontal" flexItem />
                                <Grid container spacing={3} mt={2}>
                                    <Grid item xs={6}>
                                        <Tooltip
                                            title="Бот автоматически будет переводить сообщения с различных языков на русский"
                                            placement="top">
                                            <FormControlLabel
                                                control={<Checkbox color="secondary" name="translation_enabled"
                                                    value="yes"
                                                    defaultChecked={channelData.translation_enabled}
                                                />}
                                                label="Переводить сообщения в чате"
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Tooltip
                                            title="Например Ghbdtn vbh -> Привет мир"
                                            placement="top">
                                            <FormControlLabel
                                                control={<Checkbox color="secondary" name="keyboard_translation_enabled"
                                                    value="yes"
                                                    defaultChecked={channelData.keyboard_translation_enabled}
                                                />}
                                                label="Переводить неправильную раскладку"
                                            />
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Typography variant="h5" gutterBottom mt={2}>
                                    Утилиты
                                </Typography>
                                <Divider orientation="horisontal" flexItem />
                                <Grid container spacing={3} mt={2}>
                                    <Grid item xs={6}>
                                        <Tooltip
                                            title="Активирует команду !топдня и !топ"
                                            placement="top">
                                            <FormControlLabel
                                                control={<Checkbox color="secondary" name="top_enabled"
                                                    value="yes"
                                                    defaultChecked={channelData.top_enabled}
                                                />}
                                                label="Выводить топ сообщений"
                                            />
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                {/*//save button*/}
                                {state.successSaved && (
                                    <Alert onClose={() => {
                                        setState(
                                            { ...state, successSaved: false }
                                        )
                                    }} severity="success">Настройки успешно сохранены</Alert>
                                )}
                                <Grid item xs={12}>
                                    <Button
                                        disabled={state.loading}
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Save
                                    </Button>

                                </Grid>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
        ;
}