import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ReplyIcon from '@mui/icons-material/Reply';
import Link from "@mui/material/Link";
import {Link as RouterLink, useLocation} from "react-router-dom";
import {NavLink} from "react-router-dom";
import {useEffect, useState} from "react";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import MicIcon from '@mui/icons-material/Mic';

export function MainListItems() {

    const [selectedPage, setSelectedPage] = useState(   '/dashboard')
    const location = useLocation()
    useEffect(() => {
        setSelectedPage(location.pathname)
    }, [location]);
    return (
        <React.Fragment>
            <Link to={"/dashboard"} component={NavLink} color="inherit" underline="hover">
                <ListItemButton selected={selectedPage === "/dashboard"}>
                    <ListItemIcon>
                        <DashboardIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Dashboard"/>
                </ListItemButton>
            </Link>
            <Link to={"/dashboard/channel-edit"} component={RouterLink} color="inherit" underline="hover">
                <ListItemButton selected={selectedPage === "/dashboard/channel-edit"}>
                    <ListItemIcon>
                        <AdminPanelSettingsIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Настройки канала"/>
                </ListItemButton>
            </Link>
            <Link to={"/dashboard/tts-settings"} component={RouterLink} color="inherit" underline="hover">
                <ListItemButton selected={selectedPage === "/dashboard/tts-settings"}>
                    <ListItemIcon>
                        <MicIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Озвучка сообщений"/>
                </ListItemButton>
            </Link>
            {/*<Link to={"/dashboard/timers/list"} component={RouterLink} color="inherit" underline="hover">*/}
            {/*    <ListItemButton selected={selectedPage === "/dashboard/timers/list"}>*/}
            {/*        <ListItemIcon>*/}
            {/*            <AdminPanelSettingsIcon/>*/}
            {/*        </ListItemIcon>*/}
            {/*        <ListItemText primary="Управление таймерами"/>*/}
            {/*    </ListItemButton>*/}
            {/*</Link>*/}
            {/*<Link to={"/dashboard/commands"} component={RouterLink} color="inherit" underline="hover">*/}
            {/*    <ListItemButton selected={selectedPage === "/dashboard/commands"}>*/}
            {/*        <ListItemIcon>*/}
            {/*            <ReplyIcon/>*/}
            {/*        </ListItemIcon>*/}
            {/*        <ListItemText primary="Commands"/>*/}
            {/*    </ListItemButton>*/}
            {/*</Link>*/}
            {/*<ListItemButton>*/}
            {/*    <ListItemIcon>*/}
            {/*        <PeopleIcon/>*/}
            {/*    </ListItemIcon>*/}
            {/*    <ListItemText primary="Customers"/>*/}
            {/*</ListItemButton>*/}
            {/*<ListItemButton>*/}
            {/*    <ListItemIcon>*/}
            {/*        <BarChartIcon/>*/}
            {/*    </ListItemIcon>*/}
            {/*    <ListItemText primary="Reports"/>*/}
            {/*</ListItemButton>*/}
            {/*<ListItemButton>*/}
            {/*    <ListItemIcon>*/}
            {/*        <LayersIcon/>*/}
            {/*    </ListItemIcon>*/}
            {/*    <ListItemText primary="Integrations"/>*/}
            {/*</ListItemButton>*/}
        </React.Fragment>
    );
}

export function SecondaryListItems() {
    return (
        <React.Fragment>
            {/*<ListSubheader component="div" inset>*/}
            {/*    Saved reports*/}
            {/*</ListSubheader>*/}
            {/*<ListItemButton>*/}
            {/*    <ListItemIcon>*/}
            {/*        <AssignmentIcon/>*/}
            {/*    </ListItemIcon>*/}
            {/*    <ListItemText primary="Current month"/>*/}
            {/*</ListItemButton>*/}
            {/*<ListItemButton>*/}
            {/*    <ListItemIcon>*/}
            {/*        <AssignmentIcon/>*/}
            {/*    </ListItemIcon>*/}
            {/*    <ListItemText primary="Last quarter"/>*/}
            {/*</ListItemButton>*/}
            {/*<ListItemButton>*/}
            {/*    <ListItemIcon>*/}
            {/*        <AssignmentIcon/>*/}
            {/*    </ListItemIcon>*/}
            {/*    <ListItemText primary="Year-end sale"/>*/}
            {/*</ListItemButton>*/}
        </React.Fragment>
    );
}